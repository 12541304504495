<template>
  <FocusTrap>
    <div id="mycard" class="card">

    <div class="card-header bg-secondary" style="padding-bottom: 3px;padding-top: 3px;">
      <h5>Container Reset</h5>
    </div>

    <div class="card-body">


      <div class="row">

        <div class="col-md-12">

          <!-- Static mode -->
          <div class="card">


            <div class="card-body">
              <form action="#">

                <div class="form-group row">
                  <label class="col-form-label col-md-2">Yarn Container</label>

                  <div class="col-md-2">
                    <select class="form-control" v-if="voucher" v-model="voucher.yarn_container">
                      <option value="1">Cone</option>
                      <option value="2">Babin</option>
                      <option value="3">Beam</option>
                    </select>
                  </div>

                  <label class="col-form-label col-md-1">Quality</label>
                  <div class="col-md-2">
                    <select class="form-control" v-if="voucher" v-model="voucher.quality.id">
                      <option v-for="quality in qualities" v-bind:value="quality.id">
                        {{ quality.name }}
                      </option>
                    </select>
                  </div>

                  <label class="col-form-label col-md-1 text-right">Color</label>
                  <div class="col-md-2">
                    <select class="form-control" v-if="voucher" v-model="voucher.color.id">
                      <option v-for="color in colorcharts" v-bind:value="color.id">
                        {{ color.name }}
                      </option>
                    </select>
                  </div>

                  <label class="col-form-label col-md-1 text-right">Qty</label>
                  <div class="col-md-1">
                    <input type="number" class="form-control text-right" placeholder="Qty" min="0"  v-if="voucher" v-model="voucher.qty" @change="onContainerQtyChanged" >
                  </div>

                </div>


                <div class="form-group row">
                  <label class="col-form-label col-md-6">Wastage (Kgs)</label>

                  <label class="col-form-label col-md-2 text-right">Lumps Weight</label>
                  <div class="col-md-1">
                    <input type="number" class="form-control text-right" placeholder="Enter Lumps Weight" min="0" v-if="voucher" v-model="voucher.lumps_wgt" >
                  </div>


                  <label class="col-form-label col-md-2 text-right">Yarn Waste Weight</label>
                  <div class="col-md-1">
                    <input type="number" class="form-control text-right" placeholder="Enter yarn Waste Weight" min="0" v-if="voucher" v-model="voucher.yarn_waste_wgt" >
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col-form-label col-md-4">Raw-Material Excess (Kgs)</label>

                  <div class="col-md-4"></div>

                  <div class="col-md-4">
                    <input type="number" class="form-control text-right" placeholder="Enter Weight" min="0" v-if="voucher" v-model="voucher.raw_mat_excess" >
                  </div>
                </div>

              </form>
            </div>
          </div>
          <!-- /static mode -->

        </div>
      </div>

      <div class="row">
        <div class="table-responsive" style="padding: 10px;">
          <table  id="table" class="table table-bordered">
            <thead>
            <th>#</th>
            <th>Container No</th>
            <th></th>
            <th>Container Weight</th>
            <th>Gross Weight</th>
            <th>Yarn Weight</th>
            </thead>
            <tbody>
            <tr v-for="(container,idx) in containers" style="padding: 0px;">
              <td style="padding: 0px;">{{idx + 1}}</td>
              <td style="padding: 0px;">
                <input type="text" class="form-control" placeholder="Enter Container No" maxlength="10" v-model="container.name" @change="on_container_name_change(idx, container.name)" >
              </td>
              <td style="padding: 0px;width: 70px;">
                <button class="btn btn-outline-success" @click="on_container_name_change(idx, container.name)">Load</button>
              </td>
              <td style="padding: 0px;text-align: right"> {{container.wgt | formatWeight}} </td>
              <td style="padding: 0px;">
                <input type="number" class="form-control text-right" placeholder="Enter Gross Weight" min="0" v-model="container.grs_wgt" >
              </td>
              <td style="padding: 0px;text-align: right">
                {{ (  parseFloat(container.grs_wgt)) - container.wgt | formatWeight}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-1">
          <label class="control-label text-semibold">Eb Reading</label>
          <input type="number" class="form-control text-right" placeholder="EB Reading" min="0" v-if="voucher" v-model="voucher.eb_meter" >
        </div>
        <div class="col-md-5">

        </div>

        <div class="col-md-3 text-right">

          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>

  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'ContainerResetView',
    store,
    components: {

    },
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"operator":{"id":0,"status":0,"emp_code":"","name":"","designation":{"id":0,"name":"","min_wage":0}},"yarn_container":{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0},"quality":{"id":0,"name":"","p_name":"","colors_wgt":0},"color":{"id":0,"status":0,"name":"","p_name":"","wgt":0,"chart":{}},"qty":0,"lumps_wgt":0,"yarn_waste_wgt":0,"raw_mat_excess":0,"remarks":"","eb_meter":0,"list":[]}')
      }
    },
    data () {
      return {
        machines:[],
        hdpes:[],
        shifts:[],
        emps:[],
        qualities:[],
        colorcharts:[],
        finalproducts:[],
        containers:[],
        items : new Map(),
        voucher: JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"operator":{"id":0,"status":0,"emp_code":"","name":"","designation":{"id":0,"name":"","min_wage":0}},"yarn_container":{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0},"quality":{"id":0,"name":"","p_name":"","colors_wgt":0},"color":{"id":0,"status":0,"name":"","p_name":"","wgt":0,"chart":{}},"qty":0,"lumps_wgt":0,"yarn_waste_wgt":0,"raw_mat_excess":0,"remarks":"","eb_meter":0,"list":[]}'),
      }
    },
    beforeMount () {
      const self = this;
      this.voucher = this.myvoucher;
      this.containers = [];
      this.voucher.list.forEach(function (d) {
        d.container.grs_wgt = d.wgt;
        self.containers.push(d.container);
      });

    },
    created () {
      this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      let self = this;
      self.loadData();

      if(this.$data.voucher.doc_date == '0001-01-01'){
        this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      }


      self.$data.shifts.push({'id':2,'name':'Day'});
      self.$data.shifts.push({'id':3,'name':'Night'});




      $('.form-control-select2').select2();
    },
    filters:{
      formatWeight(val){
        try {
          return val.toFixed(3);
        }catch (e) {

        }

        return val;

      }
    },
    methods:{
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },

      clear(){
        let self = this;
        self.$data.voucher = JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"operator":{"id":0,"status":0,"emp_code":"","name":"","designation":{"id":0,"name":"","min_wage":0}},"yarn_container":{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0},"quality":{"id":0,"name":"","p_name":"","colors_wgt":0},"color":{"id":0,"status":0,"name":"","p_name":"","wgt":0,"chart":{}},"qty":0,"lumps_wgt":0,"yarn_waste_wgt":0,"raw_mat_excess":0,"remarks":"","eb_meter":0,"list":[]}');
        self.$data.voucher.date = moment().format('YYYY-MM-DD');
        self.$data.containers = [];
      },
      onContainerQtyChanged(){
        const self = this;

        self.$data.containers = [];
        for (let i=0; i < self.$data.voucher.qty; i++){
          self.$data.containers.push(JSON.parse('{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0,"net_wgt":0}'));
        }
      },
      on_container_name_change(idx, name){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };


        $("#table").block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        // let container = {"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0,"net_wgt":0}
        self.$data.containers[idx].id = "";
        self.$data.containers[idx].id = "";
        self.$data.containers[idx].name = "";
        self.$data.containers[idx].weight = 0;

        fetch(`${process.env.VUE_APP_ROOT_API}v1/fms/container/name/${name}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {

            self.$data.containers[idx].id = resp.data.id;
            self.$data.containers[idx].id = resp.data.id;
            self.$data.containers[idx].name = resp.data.name;
            self.$data.containers[idx].weight = resp.data.weight;
            //self.$data.containers[idx] = resp.data;
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $("#table").unblock();
        });


      },
      on_container_qty_change(idx, name){
        const self = this;

        // let container = self.$data.containers[idx] ;
        // console.log(JSON.stringify(container));
        // container.net_wgt = container.wgt - parseFloat(container.grs_wgt);
        // console.log(JSON.stringify(container));

        // self.$data.containers[idx].grs_wgt = parseFloat(container.grs_wgt);
      },
      loadData() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.details.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      saveVoucher(){
        let self = this;


        self.$data.voucher.finyear = store.state.user.finyear;


        self.$data.voucher.yarn_container = parseInt(self.$data.voucher.yarn_container);
        self.$data.voucher.qty = parseFloat(self.$data.voucher.qty);
        self.$data.voucher.yarn_wgt = parseFloat(self.$data.voucher.yarn_wgt);

        self.$data.voucher.yarn_waste_wgt = parseFloat(self.$data.voucher.yarn_waste_wgt);
        self.$data.voucher.lumps_wgt = parseFloat(self.$data.voucher.lumps_wgt);
        self.$data.voucher.raw_mat_excess = parseFloat(self.$data.voucher.raw_mat_excess);


        //{"container":{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0},"wgt":0}
        let containers = [];
        self.$data.voucher.yarn_wgt = 0;
        self.$data.containers.forEach(function (det) {

          self.$data.voucher.yarn_wgt +=  parseFloat(det.grs_wgt) - parseFloat(det.wgt);
          let detail = {"container":{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0},"wgt":0};

          detail.container = det;
          detail.container.grs_wgt = parseFloat(det.grs_wgt);
          detail.wgt = parseFloat(det.grs_wgt) - parseFloat(det.wgt);

          containers.push(detail);
        });
        self.$data.voucher.list = containers;


        if(self.$data.voucher.machine.id < 1){
          alert("Invalid Machine");
          return
        }else if(self.$data.voucher.color.id < 1){
          alert("Invalid Color");
          return
        }else if(self.$data.voucher.quality.id < 1){
          alert("Invalid Quality");
          return
        }else if(self.$data.voucher.qty < 1){
          alert("Invalid Quantity");
          return
        }else if(self.$data.voucher.lumps_wgt < 0){
          alert("Invalid Lumps Weight");
          return
        }else if(self.$data.voucher.yarn_waste_wgt < 0){
          alert("Invalid Yarn Wastage Weight");
          return
        }else if(self.$data.voucher.raw_mat_excess < 0){
          alert("Invalid Excess Weight");
          return
        }else if(self.$data.voucher.list.length < 0){
          alert("Invalid Containers Data");
          return
        }else if(self.$data.voucher.eb_meter <= 0){
          alert("Invalid EB Reading");
          return
        }


        delete self.$data.voucher.quality.colors_wgt;
        delete self.$data.voucher.color.chart;


        let myvoucher = JSON.parse( JSON.stringify(self.$data.voucher));
        myvoucher.date = moment(self.$data.voucher.date).format('YYYY-MM-DD') + 'T00:00:00Z';

        const requestOptions = {
          method:  (self.$data.voucher.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        };

        $("#mycard").block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        fetch(`${process.env.VUE_APP_ROOT_API}v1/fms/extrusion/receipt/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.ok) {
            swal ( { title: "Success!" ,  text: data.msg, type:  "success"} );

            self.$data.voucher = JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"operator":{"id":0,"status":0,"emp_code":"","name":"","designation":{"id":0,"name":"","min_wage":0}},"yarn_container":{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0},"quality":{"id":0,"name":"","p_name":"","colors_wgt":0},"color":{"id":0,"status":0,"name":"","p_name":"","wgt":0,"chart":{}},"qty":0,"lumps_wgt":0,"yarn_waste_wgt":0,"raw_mat_excess":0,"remarks":"","eb_meter":0,"list":[]}');
            self.$data.voucher.date = moment().format('YYYY-MM-DD');

            self.$data.containers = [];

            self.$emit('extrusion_receipt_saved');

          } else {
            swal ( { title: "Oops!" ,  text: data.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: `failed to load data!`, type:  "error"} );
        }).finally(function () {
          $("#mycard").unblock();
        });
      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
